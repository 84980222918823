<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <b-form-group
                    class="mb-0"
                    label-cols="6"
                    :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                    label-size=""
                    label-class="pt-0"
            >
                <b-form-row class="float-right pr-2">
                    <!-- active -->
                    <b-form-checkbox
                            id="active"
                            switch
                            v-model="record.active"
                            name="active"
                            value="true"
                            unchecked-value="false"
                    >
                        {{activeText}}
                    </b-form-checkbox>
                    <!-- production -->
                    <b-form-checkbox
                            id="content.production"
                            class="pt-0  pl-4 ml-3"
                            v-model="record.production"
                            value="true"
                            unchecked-value="false"
                    >
                        {{$t('form.production')}}
                    </b-form-checkbox>
                </b-form-row>
            </b-form-group>
            <b-form-row>
                <!-- language -->
                <b-form-group
                        id="group-language"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$tc('form.language', 1)"
                        label-for="language"
                        :description="$t('form.locale.descriptions.language')"
                        :state="valid_language"
                >
                    <b-form-input
                            id="language"
                            v-model="record.language"
                            :state="valid_language"
                            required
                            :placeholder="$t('form.required_field')"
                    ></b-form-input>
                </b-form-group>
                <!-- locale -->
                <b-form-group
                        id="group-locale"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$t('form.locale.locale')"
                        label-for="locale"
                        :description="$t('form.locale.descriptions.locale')"
                        :state="valid_locale"
                >
                    <b-input-group>
                        <b-form-input
                                id="locale"
                                :state="valid_locale"
                                v-model="record.locale"
                                required
                                :placeholder="$t('form.required_field')"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button
                                    variant="info"
                                    href="https://github.com/umpirsky/locale-list/blob/master/data/rw/locales.php"
                                    target="_blank"
                            >
                                <b-icon icon="link45deg"></b-icon>
                            </b-button>

                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <!-- description -->
                <b-form-group
                        id="group-description"
                        class="col-sm-12 col-md-6"
                        :label="$t('form.description')"
                        label-class="mb-0"
                        label-for="description"
                        :description="$t('form.locale.descriptions.description')"
                        :state="valid_description"
                >
                    <b-form-input
                            id="description"
                            :state="valid_description"
                            v-model="record.description"
                            required
                            :placeholder="$t('form.required_field')"
                    ></b-form-input>
                </b-form-group>
                <!-- order -->
                <b-form-group
                        id="group-order"
                        class="col-sm-6 col-md-6"
                        label-class="mb-0"
                        :label="$t('form.order')"
                        label-for="order"
                        description=""
                >
                    <b-form-input
                            id="order"
                            type="number"
                            v-model="record.order"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
        </b-form>
    </div>
</template>

<script>
    export default {
        name: "FormLocale",
        components: {},
        props: {
            form: {
                valid: false,
                record: {},
            },
            modalBus: Object,
        },
        data() {
            return {
                valid: null,
                record: {
                    id: 0,
                    active: false,
                    order: 0,
                    language: '',
                    locale: '',
                    description: '',
                    production: false,
                },
            }
        },
        mounted() {
            this.modalBus.$on('submit', this.submit);

            if (this.form.record != null) {
                this.$set(this, 'record', this.form.record);
            }
        },
        methods: {
            // <-- form -->
            submit() {
                if (this.$refs.form === undefined) {
                    return;
                }
                this.valid = this.$refs.form.checkValidity();

                this.$set(this.form, 'record', this.record);
                this.$set(this.form, 'valid', this.valid);
            }
        },
        computed: {
            activeText: {
                get() {
                    return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                        ? this.$t('form.inactive')
                        : this.$t('form.active');
                }
            },
            valid_language() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.language.length > 0 ? null : false;
            },
            valid_locale() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.locale.length > 0 ? null : false;
            },
            valid_description() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.description.length > 0 ? null : false;
            },
        },
        filters: {},
        watch: {}
    }
</script>

<style scoped>

</style>
